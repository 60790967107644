.sedeo-rectangle{
    position: absolute;
    bottom: 0;
    left: -150%;
    width: 75%;
    height: 50%;
    transition: all .2s ease-in-out;
    z-index: -2;
    opacity: 0;
    @include themed($Sedeo-themes) {
        background-color: t('sedeo-main-color');
    }
}

.clickable{
    cursor: pointer;
}

.hoverable{
    transition: transform 0.2s ease-in-out;
    &:hover{
        transform: scale(1.05);
    }
}

.hoverable-bg{
    transition: all 0.2s $FLUID;
    background-color: inherit;
    &:hover{
        @include themed($Sedeo-themes) {
            background-color: t('bg-light');
        }
    }
}

.loading-container{
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    circle{
        @include themed($Sedeo-themes) {
            color: t('medium-grey');
        }
    }
}

.floating-icons-container{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    img{
        width: 60px;
        height: 60px;
        object-fit: contain;
    }

    .floating-icons{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: auto;
        height: auto;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.9;
    }
    100% {
        opacity: 0.3;
    }
}

@keyframes rotateMe {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes bounce {
    0% {
      transform: scale(1);
    }
  
    30% {
      transform: scale(1.2);
    }
  
    70% {
      transform: scale(0.9);
    }
  
    100% {
      transform: scale(1);
    }
}

@keyframes tiltMe {
    0% {
        transform: translateX(-50%) rotate(0deg);
    }
    30% {
        transform: translateX(-50%) rotate(-55deg);
    }
    50% {
        transform: translateX(-50%) rotate(0deg);
    }
    100% {
        transform: translateX(-50%) rotate(0deg);
    }
}

@keyframes dash {
    100%{
      stroke-dashoffset: 0;
    }
}

@keyframes animateStroke {
    100%{
        stroke-dasharray: 340;
        stroke-dashoffset: 0;
    }
}

@keyframes grow {
    0% {
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}




// **********************

@media (max-width: $SCREEN_MD) {
    .floating-icons-container{
        img{
            width: 50px;
            height: 50px;
        }
    }
}

@media (max-width: $SCREEN_SM) {
    .floating-icons-container{
        img{
            width: 30px;
            height: 30px;
        }
    }
}